import * as React from "react";
import "./FileMultipleEmailComponent.less";
import { LocalizeContextProps, withLocalize } from "react-localize-redux";
import { Logger } from "../../services/Logger";
import {
    Checkbox,
    DefaultButton,
    ITag,
    MessageBarType,
    ProgressIndicator,
    SearchBox,
    TeachingBubble,
    ITeachingBubbleStyles,
    DirectionalHint,
    IButtonProps,
} from "office-ui-fabric-react";
import SuggestedProjectPickerComponent from "../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import { MailboxItem } from "../../services/OfficeWrapper";
import { SmartFilingManager } from "../../services/SmartFiling/SmartFilingManager";
import { AppPage } from "../shared/navigationHeader/NavigationHeaderComponent";
import { ExpiredSessionError } from "../../models/ExpiredSessionError";
import LabelComponent from "../shared/label/LabelComponent";
import EmailListRowItemComponent from "./emailListRowItem/EmailListRowItemComponent";
import { EmailApiService } from "../../services/NewformaApi/EmailApiService";
import { EmailListItem } from "../../models/EmailListResponse";
import ProgressComponent from "../shared/progress/ProgressComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import { MsGraphApiService } from "../../services/MsGraphApiService";
import { ConfigurationsApiService } from "../../services/NewformaApi/ConfigurationsApiService";
import { OfficeNotificationService } from "../../services/officeUi/OfficeNotificationService";
import { AnalyticsActionType, AnalyticsCategoryType, AnalyticsManager } from "../../services/AnalyticsManager";
import { IProjectsService } from "../../services/NewformaApi/IProjectsService";
import { NrnServiceWrapper } from "../../services/NrnServiceWrapper";
import { AuthApiService } from "../../services/NewformaApi/AuthApiService";
import { ProjectsCacheKeys } from "../../models/StorageKeys";
import { Paging } from "../../models/ProjectKeywordsResponse";
import { InvalidateCacheService } from "../../services/NewformaApi/InvalidateCacheService";
import { MessageTypes, Messenger } from "../../services/Messenger";
import { Project } from "../../models/ProjectsResponse";
import { OfficeRoamingSettings } from "../../services/OfficeRoamingSettings";
import LinkComponent from "../shared/linkComponent/LinkComponent";

export interface EmailListItemWithSelectedStatus extends EmailListItem {
    isSelected: boolean;
}

export interface FileMultipleEmailComponentProps extends LocalizeContextProps {
    logger: Logger;
    onExpiredSession: () => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    onSetNavigationPage: (page: AppPage) => void;
    mailboxItem: MailboxItem | null;
    smartFilingManager: SmartFilingManager;
    emailApiService: EmailApiService;
    deleteEmailAfterFiling: boolean;
    msGraphApiService: MsGraphApiService;
    configurationsApiService: ConfigurationsApiService;
    officeNotificationService: OfficeNotificationService;
    analyticsManager: AnalyticsManager;
    projectsService: IProjectsService;
    invalidateCacheService: InvalidateCacheService;
    nrnServiceWrapper: NrnServiceWrapper;
    authApiService: AuthApiService;
    shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache: boolean;
    theme: string;
    messenger: Messenger;
    officeRoamingSettings: OfficeRoamingSettings;
    onDismissTeachingBubble: () => void;
    showNoProjectsToast: () => void;
    hideNoProjectsToast: () => void;
}

export interface FileMultipleEmailComponentState {
    isLoadingProjects: boolean;
    isLoadingEmails: boolean;
    isLoadingMoreEmails: boolean;
    isLoadingFolderName: boolean;
    isFiling: boolean;
    myProjects?: ExtendedITag<string>[];
    projects: ExtendedITag<string>[];
    selectedProject: ITag | null;
    loadedEmails: EmailListItem[];
    emails: EmailListItemWithSelectedStatus[];
    emailOffsetToken: string | null;
    searchTerm: string | null;
    folderName: string;
    folderId: string | null;
    currentEmailItem: EmailListItem | null;
    teachingBubbleNumber: number;
    teachingBubbleDismissed: boolean;
    isLoadingGlobalProjects: boolean;
    shouldUpdateProjects?: boolean;
}

export const fileMultipleEmailComponentDefaultState: FileMultipleEmailComponentState = {
    isLoadingProjects: true,
    isLoadingEmails: false,
    isLoadingMoreEmails: false,
    isLoadingFolderName: false,
    isFiling: false,
    projects: [],
    myProjects: [],
    selectedProject: null,
    loadedEmails: [],
    emails: [],
    emailOffsetToken: null,
    searchTerm: null,
    folderId: null,
    folderName: "",
    currentEmailItem: null,
    teachingBubbleNumber: 1,
    teachingBubbleDismissed: false,
    isLoadingGlobalProjects: false,
    shouldUpdateProjects: true,
};

 let runningTest= false;

export function setTest(flag:boolean):void { runningTest=flag}

class FileMultipleEmailComponent extends React.Component<
    FileMultipleEmailComponentProps,
    FileMultipleEmailComponentState
> {
    componentName: string = this.constructor.name;

    constructor(props: FileMultipleEmailComponentProps, context: FileMultipleEmailComponentState) {
        super(props, context);
        this.state = fileMultipleEmailComponentDefaultState;
    }

    async componentDidMount(): Promise<void> {
        this.props.logger.info(`${this.componentName} mounted`);
        this.props.onSetNavigationPage(AppPage.FileMultipleEmail);
        // invalidating cache - the first time a user uses the add-in ever, the projects should be received using NewformaLink instead of the caching service
        if (this.props.shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache) {
            await this.props.invalidateCacheService.invalidateCache(
                ProjectsCacheKeys.myProjectsCacheName,
                ProjectsCacheKeys.globalProjectsCacheName
            );
        }
        const [paging] = await Promise.all([this.loadMyProjects(), this.loadOutlookContextAndEmails()]);

        await this.loadGlobalProjects(paging);

        this.props.messenger.on<{ myProjects: Project[]; projects: Project[] }>(MessageTypes.CacheRefreshed, (data) =>
            this.projectsCacheRefreshed(this, data)
        );

        if (this.state.myProjects?.length === 0) {
            await this.refreshProjects();
        }
    }

    async loadOutlookContextAndEmails(): Promise<void> {
        await this.loadOutlookContext();
        await this.loadEmails(false, null, null);
    }

    async componentDidUpdate(
        prevProps: Readonly<FileMultipleEmailComponentProps>,
        prevState: Readonly<FileMultipleEmailComponentState>,
        snapshot?: any
    ): Promise<void> {
        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            const [folderId, currentEmailItem] = await Promise.all([
                this.props.msGraphApiService.getCurrentFolderId(),
                this.props.msGraphApiService.getCurrentMessageAsEmailListItem(),
            ]);
            const mappedItems: EmailListItemWithSelectedStatus[] = this.state.loadedEmails.map((item) => ({
                ...item,
                isSelected:
                    item.id === this.state.currentEmailItem?.id
                        ? false
                        : this.state.emails.find((x) => x.id === item.id)?.isSelected || false,
            }));
            const emails = this.moveSelectedEmailToTopOfList(mappedItems, currentEmailItem);
            this.setState({ currentEmailItem, emails });

            if (folderId === this.state.folderId) {
                return;
            }
            const folderName = await this.props.msGraphApiService.getFolderName(folderId);
            this.setState({ emails: [], emailOffsetToken: null, folderId, loadedEmails: [], folderName });
            await this.loadEmails(false, null, null);
        }
        if (prevState.selectedProject?.key !== this.state.selectedProject?.key && this.state.selectedProject?.key) {
            this.toggleProjectTeamVisibility(this.state.selectedProject?.key);
        }
    }

    private projectsCacheRefreshed(
        thisComponent: FileMultipleEmailComponent,
        data?: { myProjects: Project[]; projects: Project[] }
    ): void {
        thisComponent.props.logger.info(
            `FileMultipleEmailComponent refreshing projects from the event ${MessageTypes.CacheRefreshed}`
        );
        if (!data) {
            thisComponent.props.logger.warning(
                "FileMultipleEmailComponent data is undefined after project cache refresh"
            );
            return;
        }
        const myProjects = thisComponent.mapProjects(data.myProjects);
        if (!myProjects) {
            thisComponent.props.logger.warning(
                "FileMultipleEmailComponent myProjects is undefined after project cache refresh"
            );
            return;
        }
        thisComponent.setState({ myProjects });
        const projects = thisComponent.mapProjects(data.projects);
        thisComponent.setState({ projects });
    }

    private toggleProjectTeamVisibility(projectKey: string) {
        const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(projectKey);
        const teamMemberDiv = document.querySelector(
            ".ms-CommandBar-secondaryCommand .ms-OverflowSet-item:nth-child(2)"
        );
        isCloudProject
            ? teamMemberDiv?.classList.remove("hideProjectTeam")
            : teamMemberDiv?.classList.add("hideProjectTeam");
    }

    private async loadMyProjects(): Promise<Paging> {
        this.setState({ isLoadingProjects: true, isLoadingGlobalProjects: false });
        const globalProjectsOffsetToken = "globalProject";
        try {
            this.props.logger.info("FileMultipleEmailComponent - loading My projects.");
            const startTime = new Date();

            const projectsResponse = await this.props.projectsService.getMyProjects();
            const projectsITag: ExtendedITag<string>[] = this.mapProjects(projectsResponse.projects);
            this.setState({ projects: projectsITag, myProjects: projectsITag });
            const stopTime = new Date();
            const diffTime = (stopTime.getTime() - startTime.getTime()) / 1000;
            this.props.logger.info(
                `FileMultipleEmailComponent - My projects loaded successfully in ${diffTime.toString()} sec`
            );
            return {
                offsetToken: projectsResponse.paging?.offsetToken || globalProjectsOffsetToken,
            };
        } catch (error) {
            this.props.logger.error("FileMultipleEmailComponent - error loading My projects");
            this.handleApiError(error, this.props.translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC") as string);
            this.setState({
                projects: [],
                myProjects: [],
            });
            return {
                offsetToken: globalProjectsOffsetToken,
            };
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    private mapProjects(projects: Project[]): ExtendedITag<string>[] {
        return projects.map((project) => {
            const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
            return {
                key: project.nrn,
                name: projectDisplay,
                additionalValue: project.autoFileMailboxFolderName,
            };
        });
    }

    private async loadGlobalProjects(paging: Paging): Promise<void> {
        if (this.props.officeRoamingSettings.getShowAllGlobalProjects()) {
            this.setState({ isLoadingGlobalProjects: true });
            this.props.logger.info(`${this.componentName} - Start loading global projects.  `);

            try {
                let updatedPaging: Paging | undefined = paging;
                const startTime = new Date();
                let pageCount = 0;
                do {
                    const startTimeInner = new Date();
                    pageCount++;

                    const globalProjects: {
                        projects: ExtendedITag<string>[];
                        paging?: Paging;
                    } = await this.getGlobalProjects(updatedPaging);

                    const diffTimeInner = (Date.now() - startTimeInner.getTime()) / 1000;
                    const newProjectsState = this.state.projects.concat(globalProjects.projects);

                    const upTotal = newProjectsState.length.toString();
                    this.setState({ projects: newProjectsState });
                    updatedPaging = globalProjects.paging;
                    this.props.logger.info(
                        `${
                            this.componentName
                        } - Page ${pageCount} [ ${upTotal.toString()} ] loaded successfully ${diffTimeInner.toString()} sec. Next OffsetToken ${
                            updatedPaging?.offsetToken
                        }`
                    );
                } while (updatedPaging?.offsetToken);

                const diffTime = (Date.now() - startTime.getTime()) / 1000;

                this.props.logger.info(
                    `${this.componentName} - Loading all projects completed in ${diffTime.toString()} sec`
                );
            } catch (error) {
                this.props.logger.error(`${this.componentName} - error loading all projects`, error);
                this.handleApiError(error, this.props.translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC") as string);
            } finally {
                this.setState({ isLoadingGlobalProjects: false });
            }
        }
    }

    private async getGlobalProjects(paging: Paging): Promise<{ projects: ExtendedITag<string>[]; paging?: Paging }> {
        const projectsResponse = await this.props.projectsService.getGlobalProjects(paging);
        const projectsITag: ExtendedITag<string>[] = this.mapProjects(projectsResponse.projects);
        return {
            projects: projectsITag,
            paging: projectsResponse.paging,
        };
    }

    private async onProjectSelected(selectedProject: ITag | null): Promise<void> {
        this.setState({ selectedProject });
    }

    private async onEmailSearch(searchValue: string | null): Promise<void> {
        if (!searchValue) {
            await this.onEmailSearchClear();
            return;
        }

        this.setState({
            searchTerm: searchValue,
            isLoadingEmails: true,
            emailOffsetToken: null,
            emails: [],
            loadedEmails: [],
        });
        await this.loadEmails(false, searchValue, null);
    }

    private async onEmailSearchClear(): Promise<void> {
        this.setState({ emails: [], searchTerm: null, emailOffsetToken: null, loadedEmails: [] });
        await this.loadEmails(false, null, null);
    }

    private onEmailSelectionStateChanged(id: string, checked: boolean): void {
        const emails = this.state.emails;
        const updatedEmails = emails.map((email) => {
            if (email.id === id) {
                return { ...email, isSelected: checked };
            }
            return email;
        });
        this.setState({ emails: updatedEmails });
    }

    private selectedEmailsIds(): string[] {
        return this.state.emails.filter((email) => email.isSelected).map((email) => email.id);
    }

    private async loadEmails(
        isLoadingMore: boolean,
        searchValue: string | null,
        offsetToken: string | null
    ): Promise<void> {
        this.setState({ isLoadingEmails: !isLoadingMore, isLoadingMoreEmails: isLoadingMore });
        try {
            if (!this.state.folderId || !this.state.currentEmailItem) {
                throw new Error("missing folderId or currentEmailItem");
            }
            const emailResponse = await this.props.msGraphApiService.getFolderEmails(
                this.state.folderId,
                offsetToken,
                searchValue
            );
            const mappedItems: EmailListItemWithSelectedStatus[] = emailResponse.items.map((item) => ({
                ...item,
                isSelected: false,
            }));

            let emails = [...this.state.emails, ...mappedItems];
            if (!isLoadingMore) {
                emails = this.moveSelectedEmailToTopOfList(emails, this.state.currentEmailItem);
            }
            this.setState((state) => ({
                emails,
                loadedEmails: [...state.loadedEmails, ...emailResponse.items],
                emailOffsetToken: emailResponse.paging.offsetToken,
            }));
        } catch (error) {
            this.props.logger.error("FileMultipleEmailComponent - error loading emails");
            this.handleApiError(error, this.props.translate("FILE_MULTIPLE_EMAIL.ERROR_LOADING_EMAILS") as string);
        } finally {
            this.setState({ isLoadingEmails: false, isLoadingMoreEmails: false });
        }
    }

    private moveSelectedEmailToTopOfList(
        emails: EmailListItemWithSelectedStatus[],
        currentlySelectedEmail: EmailListItem
    ): EmailListItemWithSelectedStatus[] {
        const emailsWithoutSelected = emails.filter((email) => email.id !== currentlySelectedEmail.id);
        return [{ ...currentlySelectedEmail, isSelected: true }, ...emailsWithoutSelected];
    }

    private async loadOutlookContext(): Promise<void> {
        this.setState({ isLoadingFolderName: true });
        try {
            const [folderId, currentEmailItem] = await Promise.all([
                this.props.msGraphApiService.getCurrentFolderId(),
                this.props.msGraphApiService.getCurrentMessageAsEmailListItem(),
            ]);
            const folderName = await this.props.msGraphApiService.getFolderName(folderId);
            this.setState({ folderName, folderId, currentEmailItem });
        } catch (error) {
            this.props.logger.error("FileMultipleEmailComponent - error loading outlook context");
            this.handleApiError(error, this.props.translate("FILE_MULTIPLE_EMAIL.ERROR_LOADING_EMAILS") as string);
        } finally {
            this.setState({ isLoadingFolderName: false });
        }
    }

    private handleApiError(error: any, messageToDisplay: string): void {
        this.props.logger.error(`FileMultipleEmailComponent API error: ${messageToDisplay}`, error);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }
        this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
    }

    private isFormValid(): boolean {
        return !!this.selectedEmailsIds().length && !!this.state.selectedProject;
    }

    private isSelectAllIndeterminate(): boolean {
        const selectedEmailIds = this.selectedEmailsIds();
        return selectedEmailIds.length > 0 && selectedEmailIds.length !== this.state.emails.length;
    }

    private areAllItemsSelected(): boolean {
        const selectedEmailIds = this.selectedEmailsIds();
        return selectedEmailIds.length > 0 && selectedEmailIds.length === this.state.emails.length;
    }

    private onSelectAllChanged(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        if (checked === undefined) {
            return;
        }

        const emails = this.state.emails;

        const isSelected = checked || (!checked && this.isSelectAllIndeterminate());
        const checkedEmails = emails.map((email) => ({ ...email, isSelected }));
        this.setState({ emails: checkedEmails });
    }

    private async onFormSubmit(): Promise<void> {
        this.setState({ isFiling: true });
        const selectedEmailIds = this.selectedEmailsIds();
        const numberToFile = selectedEmailIds.length;
        const selectedProject = this.state.selectedProject as ITag;

        const culture = (await this.props.authApiService.getUserData()).culture;

        try {
            const chosenProject = this.state.projects.find((x) => x.key === selectedProject.key);
            const failedIds = await this.props.emailApiService.fileMultipleEmails(
                selectedEmailIds,
                selectedProject,
                chosenProject?.additionalValue,
                culture
            );
            const successfulIds = selectedEmailIds.filter((id) => !failedIds.includes(id));
            if (this.props.deleteEmailAfterFiling) {
                const remainingEmails = this.state.emails.filter((email) => !successfulIds.includes(email.id));
                const remainingLoadedEmails = this.state.loadedEmails.filter(
                    (email) => !successfulIds.includes(email.id)
                );
                this.setState({ emails: remainingEmails, loadedEmails: remainingLoadedEmails });
            } else {
                const allEmailsAfterSave = this.state.emails.map((email) =>
                    successfulIds.includes(email.id) ? { ...email, isFiled: true } : email
                );
                const allLoadedEmailsAfterSave = this.state.loadedEmails.map((email) =>
                    successfulIds.includes(email.id) ? { ...email, isFiled: true } : email
                );
                this.setState({ emails: allEmailsAfterSave, loadedEmails: allLoadedEmailsAfterSave });
            }
            if (failedIds.length) {
                const errorMessage = (this.props.translate("FILE_MULTIPLE_EMAIL.FILING_ERROR") as string)
                    .replace("[[count]]", failedIds.length.toString())
                    .replace("[[total]]", numberToFile.toString());
                this.props.onShowToast(errorMessage, MessageBarType.severeWarning);
                return;
            }
            const message = (this.props.translate("FILE_MULTIPLE_EMAIL.FILING_SUCCESS") as string)
                .replace("[[count]]", numberToFile.toString())
                .replace("[[projectName]]", selectedProject.name);
            this.props.onShowToast(message, MessageBarType.success);

            this.props.analyticsManager.recordEvent(
                AnalyticsCategoryType.UserActions,
                AnalyticsActionType.FileMultipleEmail
            );
        } catch (error) {
            this.props.logger.error("FileMultipleEmailComponent filing email error");
            this.handleApiError(error, "FILE_MULTIPLE_EMAIL.ERROR_FILING_EMAIL_GENERIC");
        } finally {
            const uncheckedEmails = this.state.emails.map((email) => ({ ...email, isSelected: false }));
            this.setState({ isFiling: false, emails: uncheckedEmails });
        }
    }

    private renderEmailRows(): JSX.Element {
        const emailItems = this.state.emails.map((email, index) => {
            return (
                <EmailListRowItemComponent
                    key={`${email.id}${index}`}
                    disabled={this.state.isFiling}
                    subject={email.subject}
                    from={email.from}
                    preview={email.bodyPreview}
                    id={email.id}
                    isFiled={email.isFiled}
                    isSelected={email.isSelected}
                    sentDateTime={email.sentDateTime}
                    onSelectionStateChanged={this.onEmailSelectionStateChanged.bind(this)}
                />
            );
        });

        return <>{emailItems}</>;
    }

    private getFilingMessage(): string {
        return (this.props.translate("FILE_MULTIPLE_EMAIL.FILING_IN_PROGRESS") as string).replace(
            `[[count]]`,
            `${this.selectedEmailsIds().length}`
        );
    }

    private getLoadingIndicator(): JSX.Element {
        return (
            <>
                { (this.state.isLoadingMoreEmails || this.state.isLoadingEmails || this.state.isLoadingFolderName ) && !runningTest ? (
                    <ProgressComponent
                        className="newforma-loadingEmailsIndicator"
                        message={this.props.translate("FILE_MULTIPLE_EMAIL.LOADING_EMAILS") as string}
                    />
                ) : null}
            </>
        );
    }

    private getButtonText(): string {
        const count = this.selectedEmailsIds().length ? `(${this.selectedEmailsIds().length})` : "";
        return `${(this.props.translate(
            "FILE_MULTIPLE_EMAIL.SUBMIT_BUTTON"
        ) as string).toLocaleUpperCase()} ${count}`.trim();
    }

    private firstAndSecondButtonProps(buttonText: string): IButtonProps {
        return {
            children: buttonText,
            onClick: () => {
                this.setState({ teachingBubbleNumber: this.state.teachingBubbleNumber + 1 });
            },
        };
    }

    private thirdButtonProps(buttonText: string): IButtonProps {
        return {
            children: buttonText,
            onClick: () => {
                this.setState({ teachingBubbleNumber: this.state.teachingBubbleNumber + 1 });
                return this.props.onDismissTeachingBubble();
            },
        };
    }

    private styles: Partial<ITeachingBubbleStyles> = {
        footer: {
            display: "flex",
            flexDirection: "row-reverse",
            [`& > .ms-StackItem > span`]: {
                marginRight: "75px",
            },
            color: this.props.theme === "default" ? "white" : "black",
        },
        content: {
            color: this.props.theme === "default" ? "white" : "black",
        },
        subText: {
            color: this.props.theme === "default" ? "white" : "black",
        },
        headline: {
            color: this.props.theme === "default" ? "white" : "black",
        },
        closeButton: {
            [`.ms-Icon`]: {
                color: this.props.theme === "default" ? "white" : "black",
            },
        },
    };

    private renderMultipleEmailsTeachingBubble(param: number): JSX.Element | undefined {
        switch (param) {
            case 1:
                return (
                    <TeachingBubble
                        calloutProps={{ directionalHint: DirectionalHint.bottomLeftEdge }}
                        target=".newforma-navHeaderComponent .newforma-navPrefix"
                        isWide={false}
                        hasCloseButton={true}
                        closeButtonAriaLabel={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_3") as string}
                        primaryButtonProps={this.firstAndSecondButtonProps(
                            this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_2") as string
                        )}
                        footerContent={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_4") as string}
                        onDismiss={() => this.props.onDismissTeachingBubble()}
                        headline={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_1") as string}
                        styles={this.styles}
                        ignoreExternalFocusing={true}
                    >
                        <span>{this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_1.PART_1") as string}</span>
                        <span className="teachingBubblesBoldText">
                            {this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_1.PART_2") as string}
                        </span>
                        <span>{this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_1.PART_3") as string}</span>
                        <span className="teachingBubblesBoldText">
                            {this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_1.PART_4") as string}
                        </span>
                        <span>{this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_1.PART_5") as string}</span>
                        <span className="teachingBubblesBoldText">
                            {this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_1.PART_6") as string}
                        </span>
                        <span>{this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_1.PART_7") as string}</span>
                    </TeachingBubble>
                );
            case 2:
                return (
                    <TeachingBubble
                        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                        target=".newforma-suggestedProjectPicker"
                        isWide={false}
                        hasCloseButton={true}
                        closeButtonAriaLabel={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_3") as string}
                        primaryButtonProps={this.firstAndSecondButtonProps(
                            this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_2") as string
                        )}
                        footerContent={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_5") as string}
                        onDismiss={() => this.props.onDismissTeachingBubble()}
                        headline={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_1") as string}
                        styles={this.styles}
                    >
                        <span className="teachingBubblesBoldText">
                            {this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_2.PART_1") as string}
                        </span>
                        <span>{this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_2.PART_2") as string}</span>
                    </TeachingBubble>
                );
            case 3:
                return (
                    <TeachingBubble
                        calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                        target=".newforma-emailListRowItemComponent:first-child"
                        isWide={false}
                        hasCloseButton={true}
                        closeButtonAriaLabel={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_3") as string}
                        primaryButtonProps={this.thirdButtonProps(
                            this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_3") as string
                        )}
                        footerContent={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_6") as string}
                        onDismiss={() => this.props.onDismissTeachingBubble()}
                        headline={this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_INFO.PART_1") as string}
                        styles={this.styles}
                    >
                        <span className="teachingBubblesBoldText">
                            {this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_3.PART_1") as string}
                        </span>
                        <span>{this.props.translate("TEACHING_BUBBLES_v1.BUBBLE_3.PART_2") as string}</span>
                    </TeachingBubble>
                );
        }
    }

    private async refreshProjects(): Promise<void> {
        if (!this.state.isLoadingGlobalProjects && !this.state.isLoadingProjects) {
            this.props.logger.info(`${this.componentName} Refreshing Cache`, "");
            await this.props.invalidateCacheService.invalidateCache(
                ProjectsCacheKeys.myProjectsCacheName,
                ProjectsCacheKeys.globalProjectsCacheName
            );

            const paging = await this.loadMyProjects();
            await this.loadGlobalProjects(paging);
        } else {
            this.props.logger.info(
                `${this.componentName} RefreshProjects ignored because isLoadingProjects is true`,
                ""
            );
        }
        if (this.state.myProjects?.length === 0) {
            this.props.showNoProjectsToast();
            this.props.onShowToast(
                this.props.translate("SHARED.ERRORS.NO_PROJECTS") as string,
                MessageBarType.severeWarning
            );
        } else {
            this.props.hideNoProjectsToast();
        }
    }

    render(): JSX.Element {
        return (
            <div className="newforma-fileMultipleEmailComponent">
                <div className="newforma-fileMultipleEmailComponentForm">
                    <SuggestedProjectPickerComponent
                        logger={this.props.logger}
                        className="newforma-formSpacing"
                        projects={this.state.projects}
                        onProjectSelected={this.onProjectSelected.bind(this)}
                        disabled={this.state.isLoadingProjects || this.state.isFiling}
                        smartFilingManager={this.props.smartFilingManager}
                        isLoadingProjects={this.state.isLoadingProjects}
                        mailboxItem={this.props.mailboxItem}
                        onRefresh={this.refreshProjects.bind(this)}
                        theme={this.props.theme}
                        isLoadingRemainingProjects={this.state.isLoadingGlobalProjects}
                        myProjects={this.state.myProjects}
                    />
                    <div className={`newforma-inputLabelContainer`}>
                        <LabelComponent
                            className="searchBoxLabel"
                            text={this.props.translate("FILE_MULTIPLE_EMAIL.SEARCH_BOX_LABEL") as string}
                        />
                        <LinkComponent
                            text={this.props.translate("SHARED.REFRESH_EMAILS") as string}
                            icon="Refresh"
                            onClick={() => this.onEmailSearchClear()}
                        />
                    </div>
                    {this.props.shouldShowMultipleEmailsTeachingBubbleAndInvalidateCache &&
                        this.renderMultipleEmailsTeachingBubble(this.state.teachingBubbleNumber)}
                    <SearchBox
                        className="newforma-searchBox"
                        placeholder={this.props.translate("FILE_MULTIPLE_EMAIL.SEARCH_BOX_PLACEHOLDER") as string}
                        onSearch={this.onEmailSearch.bind(this)}
                        onClear={this.onEmailSearchClear.bind(this)}
                    />
                    <div
                        className={`newforma-multipleEmailListHeader ${this.state.isLoadingFolderName ? "hidden" : ""}`}
                    >
                        <div className="newforma-selectEmailHeader">
                            <Checkbox
                                id="selectAll-checkbox"
                                className="newforma-checkbox"
                                checked={this.areAllItemsSelected()}
                                indeterminate={this.isSelectAllIndeterminate()}
                                onChange={this.onSelectAllChanged.bind(this)}
                                disabled={this.state.isLoadingEmails || this.state.isFiling}
                            />
                            <LabelComponent
                                className="emailLabel"
                                text={this.props.translate("FILE_MULTIPLE_EMAIL.SELECT_EMAIL") as string}
                                required={true}
                            />
                        </div>
                        <LabelComponent
                            className="newforma-folderLabel"
                            text={this.state.folderName}
                            showTooltip={true}
                        />
                    </div>
                    <div id="scrollableEmailList" className="newforma-scrollableEmailList">
                        {this.state.isLoadingEmails || this.state.isLoadingFolderName ? (
                            this.getLoadingIndicator()
                        ) : (
                             !runningTest?(
                            <InfiniteScroll
                                className="newforma-infiniteScroll"
                                scrollableTarget="scrollableEmailList"
                                dataLength={this.state.emails.length}
                                next={this.loadEmails.bind(
                                    this,
                                    true,
                                    this.state.searchTerm,
                                    this.state.emailOffsetToken
                                )}
                                hasMore={!!this.state.emails.length && !!this.state.emailOffsetToken}
                                loader={this.getLoadingIndicator()}
                                endMessage={
                                    <div className="newforma-endOfListMessage">
                                        {this.props.translate("FILE_MULTIPLE_EMAIL.END_OF_LIST") as string}
                                    </div>
                                }
                            >
                                {this.renderEmailRows()}
                            </InfiniteScroll> ):null) }
                    </div>
                </div>
                {this.state.isFiling ? (
                    <ProgressIndicator
                        label={this.getFilingMessage()}
                        className="newforma-progressIndicator"
                        styles={{
                            itemName: ".ms-label",
                        }}
                    />
                ) : null}
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        className="newforma-footerButton"
                        id="fileMultipleEmailsButton"
                        primary={true}
                        onClick={this.onFormSubmit.bind(this)}
                        text={this.getButtonText()}
                        disabled={!this.isFormValid() || this.state.isFiling}
                    />
                </div>
            </div>
        );
    }
}
 

export default withLocalize(FileMultipleEmailComponent);

export interface ExtendedITag<T> extends ITag {
    additionalValue: T;
}
