import AttachmentDetails = Office.AttachmentDetails;
import { Logger, Log } from "./Logger";
import EmailAddressDetails = Office.EmailAddressDetails;

type unDef = undefined | null;

export type MailboxItem = Office.Item &
    Office.ItemCompose &
    Office.ItemRead &
    Office.MessageRead &
    Office.MessageCompose &
    Office.AppointmentRead &
    Office.AppointmentCompose;

/* istanbul ignore file */
export class OfficeWrapper {
    constructor(public logger: Logger) {}

    logInfo(msg: string) {
        Log.info(msg);
    }

    addHandlerAsync(
        eventType: Office.EventType,
        handler: (type: Office.EventType) => void,
        options?: Office.AsyncContextOptions,
        callback?: (asyncResult: Office.AsyncResult<void>) => void
    ) {
        const callbackFunction = callback ? callback : (asyncResult: Office.AsyncResult<void>) => null;
        Office.context.mailbox.addHandlerAsync(eventType, handler, options || {}, callbackFunction);
    }

    displayDialogAsync(
        url: string,
        options: Office.DialogOptions,
        callback: (asyncResult: Office.AsyncResult<Office.Dialog>) => void
    ): void {
        try {
            Office.context.ui.displayDialogAsync(url, options, callback);
        } catch (error) {
            this.logger.error(
                "OfficeWrapper. There was an error with the Office.context.ui.displayDialogAsync() operation",
                error
            );
        }
    }
    removeRoamingSetting(key: string): void {
        Office.context.roamingSettings.remove(key);
    }

    setRoamingSetting(key: string, value: any): void {
        Office.context.roamingSettings.set(key, value);
    }

    getRoamingSetting(key: string): string | null {
        return Office.context.roamingSettings.get(key);
    }

    saveRoamingSettings(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            Office.context.roamingSettings.saveAsync((result: Office.AsyncResult<void>) => {
                if (result.error || result.status === Office.AsyncResultStatus.Failed) {
                    reject(result.error);
                } else {
                    resolve(result.value);
                }
            });
        });
    }

    get userProfileEmailAddress(): string {
        const mailBox = this.currentMailBox;
        return mailBox?.userProfile?.emailAddress ?? "";
    }

    get userProfileDisplayName(): string {
        const mailBox = this.currentMailBox;
        return mailBox?.userProfile?.displayName ?? "";
    }

    get userProfileEmailDomain(): string {
        const emailDomain = this.userProfileEmailAddress?.split("@");
        return emailDomain?.length ?? 0 > 1 ? emailDomain[1] : "";
    }

    getSender(): EmailAddressDetails {
        const sender = this.currentContextItem?.sender;

        if (!sender) {
            return { emailAddress: "", displayName: "", appointmentResponse: "", recipientType: "" };
        }
        return sender;
    }

    getSenderEmailAddress(): string {
        return this.currentContextItem?.sender?.emailAddress ?? "";
    }

    getContextMailboxItemSenderEmailDomain(): string {
        const emailAddress = this.currentContextItem?.sender?.emailAddress;
        const parts = emailAddress?.split("@");
        return parts?.length > 0 ? parts[1] : "";
    }

    getCurrentEmailSubject(): Promise<string> {
        const item = this.currentContextItem;
        const subject = item ? item.subject : "";
        if (typeof subject === "string" || (subject as any) instanceof String) {
            return Promise.resolve(subject);
        } else {
            return new Promise<string>((resolve, reject) => {
                (subject as any).getAsync((getSubjectResult: any) => {
                    if (!getSubjectResult.error) {
                        this.logInfo(`getCurrentEmailSubject ${getSubjectResult.value}`);
                        resolve(getSubjectResult.value);
                    } else {
                        reject(getSubjectResult.error);
                    }
                });
            });
        }
    }

    get currentMailBox(): Office.Mailbox {
        return Office.context.mailbox;
    }

    get currentContextItem(): MailboxItem {
        const item = Office.context.mailbox?.item;
        if (!item) {
            this.logInfo("currentContextItem: current item is undefined");
        }
        return item as MailboxItem;
    }

    get currentConversationId(): string {
        const item = this.currentContextItem;
        return item?.conversationId ?? "";
    }
    get currentSenderEmailAddress(): string {
        const item = this.currentContextItem;
        return item?.sender?.emailAddress ?? "";
    }

    getCurrentContextItem(): MailboxItem {
        return this.currentMailBox.item as MailboxItem;
    }
    async getCurrentEmailBody(): Promise<string> {
        const item = this.currentContextItem;
        if (!item) {
            throw new Error("Current context item is undefined.");
        }

        const body = item.body;
        console.log("getCurrentEmailBody - begin");

        try {
            if (typeof body === "string") {
                // If the body is already a string, return it directly
                return body;
            } else {
                // If the body is not a string, use getAsync to retrieve it
                const asyncResult = await new Promise<Office.AsyncResult<string>>((resolve, reject) => {
                    body.getAsync(Office.CoercionType.Text, {}, (result: Office.AsyncResult<string>) => {
                        if (result.status === Office.AsyncResultStatus.Failed) {
                            reject(result.error);
                        } else {
                            this.logInfo(`getCurrentEmailBody ${result.value}`);
                            resolve(result);
                        }
                    });
                });

                // Return the body text from the async result
                return asyncResult.value;
            }
        } catch (error) {
            console.error("Error retrieving email body:", error);
            throw error; // Re-throw the error to propagate it
        } finally {
            console.log("getCurrentEmailBody - end");
        }
    }
    /*
    getCurrentEmailBody(): Promise<string> {
        const item = this.currentContextItem;
        const body = item ? item.body : "";
        console.log('getCurrentEmailBody - begin')
        try {
            if (typeof body === "string" || (body as any) instanceof String) {
                return Promise.resolve(body.toString());
            } else {
                const result: any = body.getAsync(Office.CoercionType.Text); // das
                return Promise.resolve(result);
            }
        } finally{
            console.log('getCurrentEmailBody - end')  
        }
    } */

    getCurrentEmailAttachmentDetails(): AttachmentDetails[] {
        const item = this.currentContextItem;
        return item?.attachments ?? [];
    }

    getCurrentEmailToRecipients(): EmailAddressDetails[] {
        const item = this.currentContextItem;
        return item?.to ?? [];
    }

    getCurrentEmailCcRecipients(): EmailAddressDetails[] {
        const item = this.currentContextItem;
        return item?.cc ?? [];
    }

    getCurrentEmailDate(): Date {
        const item = this.currentContextItem;
        return item?.dateTimeCreated ?? new Date(12, 31, 1999);
    }

    isMailboxVersionSupported(versionToCheck: string): boolean {
        return Office.context.requirements.isSetSupported("mailbox", versionToCheck);
    }

    async getInternetHeader(headerName: string): Promise<string | null> {
        const mailboxItem = this.currentContextItem;
        if (!mailboxItem) {
            throw new Error("Mailbox item is undefined.");
        }

        try {
            const asyncResult = await new Promise<Office.AsyncResult<Office.InternetHeaders>>((resolve, reject) => {
                mailboxItem.internetHeaders.getAsync(
                    [headerName], // First parameter: array of header names
                    {}, // Second parameter: options object (can be empty)
                    (result) => {
                        if (result.status === Office.AsyncResultStatus.Failed) {
                            reject(asyncResult.error);
                        } else {
                            this.logInfo(`getInternetHeader ${result.value}`);
                            resolve(result);
                        }
                    }
                );
            });

            // Extract the header value from the result
            const headerValue = asyncResult.value ? (asyncResult.value as any)[headerName] : null;
            return headerValue;
        } catch (error) {
            console.error("Error retrieving internet header:", error);
            return null;
        }
    }

    // recoded
    async removeInternetHeaders(headerNames: string[]): Promise<void> {
        const item = this.currentContextItem;

        if (!item) {
            throw new Error("Mailbox item is undefined.");
        }

        const headersToRemove: { [key: string]: string } = {};
        headerNames.forEach((name) => {
            headersToRemove[name] = "";
        });

        await item.internetHeaders.setAsync(headersToRemove);
    }

    async setInternetHeaders(headers: Object): Promise<void> {
        const item = this.currentContextItem;
        if (!item) {
            return Promise.reject(new Error("Mailbox item is undefined."));
        }

        await item.internetHeaders.setAsync(headers);
    }

    convertItemIdToRestId(itemId: string): string {
        const mailBox = this.currentMailBox;
        return mailBox?.convertToRestId(itemId, Office.MailboxEnums.RestVersion.v2_0);
    }

    convertToRestId(item: MailboxItem): string {
        const itemId = item?.itemId ?? "";

        return this.convertItemIdToRestId(itemId); // Office.context.mailbox.convertToRestId( itemId, Office.MailboxEnums.RestVersion.v2_0);
    }

    getCurrentMessageNrn(): string {
        const item = this.currentContextItem;
        const restId = this.convertItemIdToRestId(item?.itemId ?? "");
        return `nrn:email:message:ms-graph:${restId}`;
    }

    getRestUrl(): string {
        const mailBox = this.currentMailBox;
        return mailBox?.restUrl ?? "";
    }

    private async getCallbackToken(): Promise<string> {
        const mailBox = this.currentMailBox;
        return new Promise((resolve, reject) => {
            mailBox.getCallbackTokenAsync((asyncResult) => {
                if (asyncResult.error) {
                    this.logger.error(
                        "OfficeWrapper. There was an error retrieving ewsCallbackToken",
                        asyncResult.error
                    );
                    reject(asyncResult.error);
                } else {
                    resolve(asyncResult.value);
                }
            });
        });
    }

    // under some circumstances the getCallbackTokenAsync method fails with a generic error 9018. it seems to be something on microsoft's side.
    async getCallbackTokenWithRetry(attempt: number = 1, error?: any): Promise<string> {
        const maxAttempts = 5;
        if (attempt > maxAttempts) {
            this.logger.error(`OfficeWrapper. Could not get ewsCallbackToken after ${maxAttempts} attempts`);
            return Promise.reject(error);
        }
        try {
            return await this.getCallbackToken();
        } catch (error) {
            const incrementedAttempt = attempt + 1;
            await new Promise((resolve) => {
                setTimeout(resolve, 500);
            }); // sleep
            return this.getCallbackTokenWithRetry(incrementedAttempt, error);
        }
    }

    getLatestSupportedOfficeApiVersion(): string | null {
        let setIsSupported = true;
        let minorVersion = 0;
        while (setIsSupported) {
            setIsSupported = Office.context.requirements.isSetSupported("mailbox", `1.${minorVersion}`);
            if (!setIsSupported) {
                minorVersion--;
                break;
            }
            minorVersion++;
        }

        return minorVersion >= 0 ? `1.${minorVersion}` : null;
    }

    getClientPlatform(): string {
        const platform = Office.context.diagnostics.platform;

        switch (platform) {
            case Office.PlatformType.Android:
                return "Android";
            case Office.PlatformType.iOS:
                return "iOS";
            case Office.PlatformType.Mac:
                return "Mac";
            case Office.PlatformType.OfficeOnline:
                return "Office Online";
            case Office.PlatformType.PC:
                return "Windows";
            case Office.PlatformType.Universal:
                return "WinRT";
            default:
                return "unknown";
        }
    }

    getClientVersion(): string {
        return Office.context.diagnostics.version;
    }

    getTimezone(): string {
        return Office.context.mailbox.userProfile.timeZone;
    }

    getCurrentMessageId(): string {
        const mailBox = this.currentMailBox;
        const itemId = mailBox.item?.itemId ?? "";

        if (mailBox.diagnostics.hostName === "OutlookIOS") {
            // itemId is already REST-formatted.
            return itemId;
        } else {
            // Convert to an item ID for API v2.0.
            return mailBox.convertToRestId(itemId, Office.MailboxEnums.RestVersion.v2_0);
        }
    }

    async getCcAsync(): Promise<EmailAddressDetails[]> {
        const cc: any = this.currentContextItem?.cc ?? [];

        if (cc instanceof Array) {
            return Promise.resolve(cc);
        }
        return new Promise((resolve, reject) => {
            cc.getAsync((result: any) => {
                if (result.error) {
                    this.logger.error(
                        "OfficeWrapper. There was an error retrieving the 'cc' recipients of the email",
                        result.error
                    );
                    reject(result.error);
                }
                this.logInfo(`getCcAsync ${result.value}`);
                resolve(result.value);
            });
        });
    }

    async getToAsync(): Promise<EmailAddressDetails[]> {
        // if defined as Const... the compiler has issues
        const to: any = this.currentContextItem?.to ?? ([] as EmailAddressDetails[]);

        if (to instanceof Array) {
            return Promise.resolve(to);
        }

        return new Promise((resolve, reject) => {
            to.getAsync((result: any) => {
                if (result.error) {
                    this.logger.error(
                        "OfficeWrapper. There was an error retrieving the 'to' recipients of the email",
                        result.error
                    );
                    reject(result.error);
                }
                resolve(result.value);
            });
        });
    }

    // das
    async setToAsync(emails: EmailAddressDetails[]): Promise<void> {
        // if defined as Const... the compiler has issues
        const to: any = this.currentContextItem?.to;
        return new Promise((resolve, reject) => {
            to?.setAsync(emails, (result: any) => {
                if (result.error) {
                    this.logger.error("OfficeWrapper. There was an error updating the 'to' recipients", result.error);
                    reject(result.error);
                }
                resolve(result.value);
            });
        });
    }
    // das
    async setCcAsync(emails: EmailAddressDetails[]): Promise<void> {
        // if defined as Const... the compiler has issues
        const cc: any = this.currentContextItem?.cc;
        return new Promise((resolve, reject) => {
            // some strangeness here.. there doesn't seem to be a 'setAsync on to
            cc?.setAsync(emails, (result: Office.AsyncResult<void>) => {
                if (result.error) {
                    this.logger.error("OfficeWrapper. There was an error updating the 'cc' recipients", result.error);
                    reject(result.error);
                }
                resolve(result.value);
            });
        });
    }

    isReadMode(): boolean {
        const item = Office.context.mailbox.item;

        return !!item?.itemId;
    }
}
