import "./SubmittalReviewResponseComponent.less";
import * as React from "react";
import { LocalizeContextProps, TranslateFunction, withLocalize } from "react-localize-redux";
import { AppPage } from "../../shared/navigationHeader/NavigationHeaderComponent";
import {
    DefaultButton,
    IPersonaProps,
    ISuggestionItemProps,
    ITag,
    Label,
    MessageBarType,
    ProgressIndicator,
    TagPicker,
    TextField,
} from "office-ui-fabric-react";
import { Log, Logger } from "../../../services/Logger";
import SuggestedProjectPickerComponent, {
    ProjectITag,
} from "../../shared/suggestedProjectPicker/SuggestedProjectPickerComponent";
import { SmartFilingManager } from "../../../services/SmartFiling/SmartFilingManager";
import { MailboxItem, OfficeWrapper } from "../../../services/OfficeWrapper";
import AttachmentsComponent from "../../shared/attachments/AttachmentsComponent";
import FromAndToComponent from "../../shared/fromAndToComponent/FromAndToComponent";
import { FormValidationHelpers } from "../../../helpers/FormValidationHelpers";
import SuggestedItemWithSubtextComponent from "../../shared/pickerSuggestedItems/suggestedItemWithSubtext/SuggestedItemWithSubtextComponent";
import { ExpiredSessionError } from "../../../models/ExpiredSessionError";
import { ApiRequestErrorLevel } from "../../../models/ApiRequestErrorWithMessage";
import { SubmittalsApiService } from "../../../services/NewformaApi/SubmittalsApiService";
import { SubmittalReviewResponseBodyParams } from "../../../models/workflow/submittal/SubmittalWorkflowActions";
import { IProjectsService } from "../../../services/NewformaApi/IProjectsService";
import { WorkflowActionType } from "../../../models/workflow/WorkflowActionType";
import LabelComponent from "../../shared/label/LabelComponent";
import { AnalyticsManager } from "../../../services/AnalyticsManager";
import TranslatedDatePickerComponent from "../../shared/translatedDatePicker/TranslatedDatePickerComponent";
import { NrnServiceWrapper } from "../../../services/NrnServiceWrapper";
import ChipPickerComponent from "../../shared/chipPicker/ChipPickerComponent";
import {
    DetailedKeyword,
    KeywordListType,
    DetailedKeyword as ProjectKeyword,
} from "../../../models/ProjectKeywordsResponse";
import { ElementVisibility } from "../../../models/shared/ElementVisibility";
import { MsGraphApiService } from "../../../services/MsGraphApiService";
import KeywordsDropdown from "../../shared/keywordsDropdown/KeywordsDropdown";
import { ProjectHelper } from "../../../helpers/ProjectHelper";
import { ProjectsCacheKeys } from "../../../models/StorageKeys";
import { Submittal } from "../../../models/workflow/submittal/SubmittalsResponse";
import { InvalidateCacheService } from "../../../services/NewformaApi/InvalidateCacheService";
import { AttachmentItem } from "../../../models/shared/AttachmentList";
import HTMLEditor from "../../shared/editor/Editor";
import { ConfigurationService } from "../../../services/ConfigurationService";
import { AttachmentDataHelpers } from "../../../helpers/AttachmentDataHelpers";
import ForwardCheckbox from "../../shared/checkbox/forwardCheckbox";
import RespondCloseSubmittal from "../respondCloseSubmittal/respondCloseSubmittal";
import { EmailRecipients } from "../../../models/shared/FileTransferEmailFieldsDetails";
import { DateHelper } from "../../../helpers/DateHelpers";

import { delay } from "../../../helpers/UtilityHelper";
export interface SubmittalReviewResponseComponentProps extends LocalizeContextProps {
    logger: Logger;
    onExpiredSession: () => void;
    onSetNavigationPage: (page: AppPage) => void;
    onShowToast: (message: string | null, type: MessageBarType) => void;
    smartFilingManager: SmartFilingManager;
    officeWrapper: OfficeWrapper;
    formValidationHelpers: FormValidationHelpers;
    submittalsApiService: SubmittalsApiService;
    projectsService: IProjectsService;
    mailboxItem: MailboxItem | null;
    analyticsManager: AnalyticsManager;
    nrnServiceWrapper: NrnServiceWrapper;
    msGraphApiService: MsGraphApiService;
    theme: string;
    invalidateCacheService: InvalidateCacheService;
    isFilePathInAttachmentsSupported?: boolean;
    isFileTransferAndEditorSupported: boolean;
    configService: ConfigurationService;
    isRespondCloseSupported: boolean;
}

export interface SubmittalReviewResponseComponentState {
    projects: ITag[];
    selectedProject: ITag | null;
    isLoadingProjects: boolean;
    isFiling: boolean;
    attachments: AttachmentItem[];
    from: IPersonaProps[];
    to: IPersonaProps[];
    cc: IPersonaProps[];
    remarks: string;
    responses?: DetailedKeyword[];
    selectedResponse: DetailedKeyword | undefined;
    isLoadingKeywords?: boolean;
    isLoadingSubmittals?: boolean;
    submittals?: ITag[];
    selectedSubmittal: ITag | undefined;
    fileUploadIsInProgress: boolean;
    failedUploadAttachmentNames: string[];
    receivedDate: Date | undefined;
    keywords?: IPersonaProps[];
    selectedKeywords: IPersonaProps[];
    allowCustomKeywords: boolean;
    subject: string;
    areToAndCCSame: boolean;
    shouldRespondAndClose: boolean;
    shouldShowRespondCloseOptions?: boolean;
    isRespondCloseFormValid: boolean;
    respondAndCloseDate: Date | undefined;
    respondAndCloseEmailRecipients: EmailRecipients;
    isRespondAndCloseValid: boolean;
    respondAndCloseResponse: string | undefined;
    isLoadingPurposes: boolean;
    respondAndCloseBackButtonClicked: false;
    shouldClearRespondAndCloseValues: boolean;
    emailRecipients: EmailRecipients;
    respondAndCloseKeywords: IPersonaProps[];
    respondAndCloseSelectedKeywords: IPersonaProps[];
}

export const submittalReviewResponseComponentDefaultState: SubmittalReviewResponseComponentState = {
    projects: [],
    selectedProject: null,
    isLoadingProjects: false,
    isFiling: false,
    attachments: [],
    from: [],
    to: [],
    cc: [],
    remarks: "",
    selectedResponse: undefined,
    selectedSubmittal: undefined,
    selectedKeywords: [],

    fileUploadIsInProgress: false,
    failedUploadAttachmentNames: [],
    receivedDate: undefined,
    allowCustomKeywords: false,
    subject: "",
    areToAndCCSame: false,
    shouldRespondAndClose: false,
    shouldShowRespondCloseOptions: false,
    isRespondCloseFormValid: false,
    respondAndCloseDate: DateHelper.TodayDate,
    respondAndCloseEmailRecipients: {
        to: [],
        cc: [],
    },
    isRespondAndCloseValid: false,
    respondAndCloseResponse: "",
    isLoadingPurposes: false,
    respondAndCloseBackButtonClicked: false,
    shouldClearRespondAndCloseValues: false,
    emailRecipients: {
        to: [],
        cc: [],
    },
    respondAndCloseKeywords: [],
    respondAndCloseSelectedKeywords: [],
};

class SubmittalReviewResponseComponent extends React.Component<
    SubmittalReviewResponseComponentProps,
    SubmittalReviewResponseComponentState
> {
    componentName: string = this.constructor.name;
    submittalRef: any;

    _submittalCount: any = "";

    NO_PROJECT_SELECTED: any = "";
    LOADING: any = "";
    NO_ITEMS: any = "";
    SUBMITTAL_PICKER_HEADER: any = "";
    SUBMITTAL_NONE: any = "";
    SUBMITTAL_PLACEHOLDER: any = "";
    RESPONSE_LABEL: any = "";
    RESPONSE_PLACEHOLDER: any = "";
    SUBMITTAL_LABEL: any = "";

    constructor(props: SubmittalReviewResponseComponentProps, context: SubmittalReviewResponseComponentState) {
        super(props, context);

        this.state = submittalReviewResponseComponentDefaultState;

        this.submittalRef = React.createRef();
        this.componentName = "SubmittalReviewResponse.";
    }

    async componentDidMount() {
        document.querySelector(".submittalReviewResponseComponent")?.scrollTo({
            top: 0,
            behavior: "auto",
        });
        this.props.onSetNavigationPage(AppPage.FileAsSubmittalReviewResponse);

        await Promise.all([this.populateDefaults(), this.loadProjects()]);
    }

    componentDidCatch(error: any, info: any) {
        Log.error(`${this.componentName}An error occurred: ${error}, ${info}`);
    }

    clearCache() {
        this.props.submittalsApiService?.clearCache(); // the memory cache gets synched with external LS cache.
        this._submittalCount = undefined;
        this.setState({ submittals: [] });
    }

    async componentDidUpdate(
        prevProps: Readonly<SubmittalReviewResponseComponentProps>,
        prevState: Readonly<SubmittalReviewResponseComponentState>
    ) {
        if (prevProps === this.props) {
            return;
        }
        if (this.props.mailboxItem && prevProps.mailboxItem !== this.props.mailboxItem) {
            this.setState((state) => ({
                ...submittalReviewResponseComponentDefaultState,
                projects: state.projects,
                selectedProject: state.selectedProject,
                from: state.from,
                to: state.to,
                cc: state.cc,
            }));

            await this.populateDefaults();
        }

        if (
            !this.state.shouldShowRespondCloseOptions &&
            this.state.shouldShowRespondCloseOptions !== prevState.shouldShowRespondCloseOptions
        ) {
            this.setState({ respondAndCloseBackButtonClicked: false });
        }
    }

    private async populateDefaults(): Promise<void> {
        const attachments = this.props.officeWrapper.getCurrentEmailAttachmentDetails();

        const attachmentsToUse = attachments.filter((attachment) => !attachment.isInline);

        const attachmentsNoCloud = attachmentsToUse.filter(
            (attachment) => attachment.attachmentType !== Office.MailboxEnums.AttachmentType.Cloud
        );

        const remarks = await this.props.msGraphApiService.getCurrentClearMessageBody(
            this.props.isFileTransferAndEditorSupported
        );

        const receivedDate = this.props.officeWrapper.getCurrentEmailDate();

        const subject =
            (await this.props.officeWrapper.getCurrentEmailSubject()) ||
            (this.props.translate("SUBMITTALS.RESPONSE.NO_SUBJECT") as string).trim();

        const attachmentDetails = await this.props.msGraphApiService.getAttachmentDetails();
        const htmlBody = AttachmentDataHelpers.replaceCIDReferences(remarks, attachmentDetails);

        if (subject !== this.state.subject) {
            this.setState({ subject });
        }
        this.setState({
            attachments: attachmentsNoCloud,
            remarks: this.props.isFileTransferAndEditorSupported ? htmlBody.trim() : remarks.trim(),
            receivedDate,
            subject: subject.trim(),
        });
    }

    private async loadProjects(): Promise<void> {
        this.setState({ isLoadingProjects: true });
        try {
            const projectsResponse = await this.props.projectsService.getProjectsSupportingSubmittals();
            Log.info(`after load projects ${projectsResponse}`);

            const projectsITag: ITag[] = projectsResponse.projects.map((project) => {
                const projectDisplay = project.number ? `${project.number} - ${project.name}` : project.name;
                return { key: project.nrn, name: projectDisplay };
            });
            this.setState({ projects: projectsITag });
        } catch (error) {
            Log.error(`${this.componentName}.${error}`);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.onShowToast(
                this.translate("SHARED.ERRORS.LOADING_PROJECTS_GENERIC"),
                MessageBarType.severeWarning
            );
        } finally {
            this.setState({ isLoadingProjects: false });
        }
    }

    handleMouseDownSubmittals(event: any) {
        if (event.ctrlKey) {
            this.clearCache();
            if (this.state.selectedProject) {
                this.loadSubmittals(this.state.selectedProject)
                    .then((result) => {
                        // Handle the resolved value here
                        Log.infoStyle("Reload: Submittals Completed", "green");
                    })
                    .catch((error) => {
                        // Handle errors here
                        Log.infoStyle("Reload SubmittalsFailed", "red");
                    });
            }
        }
    }

    private abortSubmittalsIfAny() {
        this.props.submittalsApiService?.doAbort();
    }

    private async onProjectSelected(selectedProject: ITag | null): Promise<void> {
        Log.infoStyle(`** onProjectSelected [${selectedProject?.name}]`);

        if (this.state.respondAndCloseBackButtonClicked && !this.state.shouldShowRespondCloseOptions) {
            return;
        }
        // Only trigger state changes if the project changed from last time
        if (selectedProject !== this.state.selectedProject) {
            this.abortSubmittalsIfAny();
            this._submittalCount = undefined; // reset

            if (!selectedProject) {
                this.clearKeywords();
                // set selected project to null and clear the values in respond and close
                this.setState({
                    selectedProject: null,
                    respondAndCloseDate: undefined,
                    respondAndCloseResponse: "",
                    respondAndCloseSelectedKeywords: [],
                    respondAndCloseEmailRecipients: { to: [], cc: [] },
                    shouldClearRespondAndCloseValues: false,
                });
                return;
            }

            const shouldRespondAndClose = false;

            this.setState({ selectedProject, shouldRespondAndClose });

            // loadKeywords takes about 5  secs... it should also have cached results
            await Promise.all([this.loadKeywords(selectedProject), this.loadSubmittals(selectedProject)]);
        }
    }

    private clearKeywords(): void {
        this.setState({
            selectedResponse: undefined,
            selectedSubmittal: undefined,
            submittals: [],
            keywords: [],
            responses: [],
            selectedKeywords: [],
            allowCustomKeywords: false,
        });
    }

    updateSubmittals(value: any) {
        Log.info(`Callback ${value} ${this.state.isLoadingSubmittals ?? ""}`);
        this._submittalCount = value;
    }

    _currentId = 0;

    getNewId():number {
        this._currentId++;
        return this._currentId ;
    }
    private async loadSubmittals(project: ITag): Promise<void> {
        // this.checkCache();
        const subService = this.props.submittalsApiService;
        try {
            this.setState({ isLoadingSubmittals: true }); 
            subService.doAbort();
            let submittals = subService.loadCacheSubmittals(project.name);
            const curId = this.getNewId();
           
            if (!submittals || submittals.length === 0) {
                const abortCount = subService.AbortCount;
                const submittalsResponse = await subService.getForwardedSubmittals(
                    project.key,
                    this.updateSubmittals.bind(this)
                );

                if (subService.AbortCount === abortCount) {
                    submittals = submittalsResponse.map((submittal: Submittal) => ({
                        name: submittal.number,
                        key: submittal.nrn,
                        description: submittal.subject,
                    }));

                    subService.saveCacheSubmittals(project.name, submittals);
                } else {
                    Log.info("\r\nAbort in LoadSubmittals");
                }
            }
            Log.info(`${this.componentName}loadSubmittals ${submittals?.length}`);
            // in case this was called async, and two inflights finish differently.. ignore anything less than the tip
            if (curId === this._currentId)
            {
                Log.infoStyle(`Accepting: ${curId} `,'green')
                this.setState({ submittals }, () => {
                    // This callback function will be executed
                    // after the component has re-rendered
                    this.setState({ isLoadingSubmittals: false });
                }); 
                this.setState({ isLoadingSubmittals: false });
            } else
            {
                Log.infoStyle(`Rejecting: ${curId} vs ${this._currentId}`,'red')
            }
           
        } catch (error) {
            Log.error(`${this.componentName}loadSubmittals ${error}`);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            const errorMessage =
                (error as any).status === 405
                    ? this.translate("SUBMITTALS.ACTIVITY_CENTER_DISABLED_ERROR")
                    : this.translate("SUBMITTALS.RESPONSE.FAILED_LOADING_SUBMITTALS");
            this.props.onShowToast(errorMessage as string, MessageBarType.severeWarning);
        } finally {
            this.setState({ isLoadingSubmittals: false });
        }
    }

    private async loadKeywords(project: ITag): Promise<void> {
        const subService = this.props.submittalsApiService;
        const projService = this.props.projectsService;

        Log.info("loadKeyword");
        const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(project.key);
        const actionType = isCloudProject ? WorkflowActionType.ReviewResponse : WorkflowActionType.ReviewResponseNpc;
        try {
            this.setState({ isLoadingKeywords: true });
            Log.info("fetching response");
            const workflowActionKeywordsResponse = await subService.getWorkflowActionKeywords(project.key, actionType);
            const sortedWorkflowActionKeywords = ProjectHelper.sortKeywords(workflowActionKeywordsResponse);
            Log.info("setting response state");
            this.setState({ responses: sortedWorkflowActionKeywords });

            if (!isCloudProject) {
                const keywordsResponse = await projService.getProjectKeywords(
                    project.key,
                    KeywordListType.SubmittalKeyword
                );
                const sortedKeywords = ProjectHelper.sortKeywords(keywordsResponse);
                const keywords: IPersonaProps[] = sortedKeywords.map((keyword) => ({
                    text: keyword.name,
                    data: keyword,
                }));
                this.setState({ keywords, allowCustomKeywords: keywordsResponse.allowCustom });
            }
        } catch (error) {
            Log.error(`${this.componentName}.loadKeywords ${error}`);

            if (ExpiredSessionError.isInstanceOf(error)) {
                this.props.onExpiredSession();
                return;
            }

            this.props.onShowToast(
                this.props.translate("SHARED.ERRORS.FAILED_LOADING_KEYWORDS") as string,
                MessageBarType.severeWarning
            );
        } finally {
            const selectedKeywords: any = [];
            this.setState({ isLoadingKeywords: false, selectedKeywords });
        }
    }

    private onFromChange(people: IPersonaProps[]): void {
        this.setState({ from: people });
    }

    private onToChange(people: IPersonaProps[]): void {
        this.setState({ to: people });
    }

    private onCcChange(people: IPersonaProps[]): void {
        this.setState({ cc: people });
    }

    private onAttachmentsUpdated(files: AttachmentItem[]): void {
        this.setState({
            attachments: files,
        });
        document.querySelector(".submittalReviewResponseComponent")?.scrollTo({
            top: document.querySelector(".submittalReviewResponseComponent")?.scrollHeight,
            behavior: "auto",
        });
    }

    moveToRespondClose() {
        this.setState({
            shouldShowRespondCloseOptions: true,
            emailRecipients: { to: this.state.to, from: this.state.from },
        });
        Log.info(`${this.componentName}.moveToRespondClose`);
    }

    shouldGoBackToSubmittalResponse() {
        this.setState({ shouldShowRespondCloseOptions: false });
        Log.info("Changing to Submittal Response view");
    }

    updateForwardSubmittalCheckbox() {
        this.setState({ shouldRespondAndClose: !this.state.shouldRespondAndClose });
    }

    updateRespondAndCloseKeywords(option: IPersonaProps[], keywords: IPersonaProps[]): void {
        this.setState({ respondAndCloseSelectedKeywords: option, respondAndCloseKeywords: keywords });
    }

    private isRespondCloseDisabled = (): boolean => {
        const project = this.state.selectedProject;
        if (project) {
            return false;
        }
        return true;
    };

    private onRemarksChange(newValue?: string): void {
        this.setState({ remarks: newValue || "" });
    }

    private onOldEditorRemarksChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ): void {
        this.setState({ remarks: newValue || "" });
    }

    private onResponseSelectionChange(option: DetailedKeyword | null): void {
        this.setState({ selectedResponse: option ?? undefined });
    }
    private onResponseBlur(): void {
        this.setState({ selectedResponse: undefined });
    }
    private onSubmittalBlur(): void {
        Log.info("onSubmittalBlur");
        if (!this.state.selectedSubmittal) {
            Log.info("reset");
        }
    }
    private getKeywordPlaceholder(itemCount: any, selectText: string): string {
        if (!this.state.selectedProject) {
            return this.NO_PROJECT_SELECTED;
        }

        if (this.state.isLoadingSubmittals) {
            return this.LOADING;
        }

        if (!itemCount || itemCount <= 0) {
            return this.NO_ITEMS;
        }
        return selectText;
    }

    private onRenderSubmittalNumbers(props: ITag, itemProps: ISuggestionItemProps<any>): JSX.Element {
        return <SuggestedItemWithSubtextComponent primaryText={props.name} subtext={(props as any).description} />;
    }

    async onSubmittalsFiltered(filter: string, selectedItems?: ITag[]): Promise<ITag[]> {
        if (!this.state.submittals) {
            return [];
        }

        if (!filter) {
            return this.state.submittals;
        }

        return this.state.submittals.filter((x) => {
            return (
                x.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
                (x as any).description.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
            );
        });
    }

    private onSubmittalSelected(selectedItem?: ITag): ITag | null {
        if (!selectedItem) {
            this.setState({ selectedSubmittal: undefined, selectedKeywords: [] });
            return null;
        }

        this.props.submittalsApiService
            .getSubmittalDetails(selectedItem.key as string, this.state.selectedProject?.key as string)
            .then((submittalDetails) => {
                const keywords = submittalDetails.keywords.map((keyword) => ({ text: keyword.name, data: keyword }));
                this.setState({ selectedKeywords: keywords });
                this.setState({ respondAndCloseEmailRecipients:{ to:[{ text:submittalDetails.from?.email}], from: [] }});
            })
            .catch((error) => {
                this.handleApiError(error);
            });
        this.setState({
            selectedSubmittal: {
                ...selectedItem,
                name: `${selectedItem.name} ${(selectedItem as any).description}`.trim(),
            },
        });

        return selectedItem;
    }

    private onSubmittalChange(items?: ITag[]): void {
        if (!items?.length) {
            this.setState({ selectedSubmittal: undefined, selectedKeywords: [] });
        }
    }

    onEmptySubmittalInputFocus(selectedItems?: ITag[]): ITag[] {
        Log.info("onEmptySubmittalInput");
        if (this.state.submittals) {
            return this.state.submittals;
        }

        return [];
    }

    private async onFormSubmit(): Promise<void> {
        this.setState({ isFiling: true });
        try {
            const formHelper = this.props.formValidationHelpers;

            const messageNrn = this.props.officeWrapper.getCurrentMessageNrn();
            const submittalDetails = await this.props.submittalsApiService.getSubmittalDetails(
                this.state.selectedSubmittal?.key as string,
                this.state.selectedProject?.key as string
            );
            const isCloudProject = this.props.nrnServiceWrapper.isCloudProject(
                this.state.selectedProject?.key as string
            );

            const requestParams: SubmittalReviewResponseBodyParams = {
                from: formHelper.mapPersonaPropsToContact(this.state.from)[0],
                to: formHelper.mapPersonaPropsToContact(this.state.to),
                response: formHelper.mapDetailedKeywordToKeyword(this.state.selectedResponse as DetailedKeyword),
                version: submittalDetails.version,
                cc: this.state.cc.length ? formHelper.mapPersonaPropsToContact(this.state.cc) : undefined,

                remarks: this.state.remarks?.trim() || undefined,
                via: this.props.submittalsApiService.getVia(this.state.selectedProject?.key as string),
                subject: isCloudProject ? undefined : this.state.subject,
                keywords: isCloudProject
                    ? undefined
                    : formHelper.mapPersonaPropsToKeywords(this.state.selectedKeywords),
                receivedDate: isCloudProject ? undefined : this.state.receivedDate?.toISOString(),
            };
            await this.props.submittalsApiService.logEmailAsSubmittalReviewerResponse(
                this.state.selectedProject?.key as string,
                messageNrn,
                this.state.selectedSubmittal?.key as string,
                requestParams,
                this.state.attachments,
                this.fileUploadCallback.bind(this),
                this.props.isFileTransferAndEditorSupported
            );

            try {
                const projectAsAny = this.state.selectedProject as any;
                this.props.analyticsManager.recordSmartFilingEvents(
                    projectAsAny.suggestedProject,
                    projectAsAny.suggestionIndex
                );
                await this.props.smartFilingManager.addToFiledHistory(
                    this.state.selectedProject ?? { key: "", name: "" },
                    this.props.mailboxItem?.conversationId as string,
                    this.props.mailboxItem?.sender.emailAddress as string
                );
            } catch (error) {
                Log.error(`${this.componentName} failed to update smart filing history ${error}`);
            }

            this.props.onShowToast(
                this.props.translate("SUBMITTALS.RESPONSE.SUCCESS_MESSAGE") as string,
                MessageBarType.success
            );
            this.setState({
                selectedSubmittal: undefined,
                selectedResponse: undefined,
                selectedKeywords: [],
            });
        } catch (error) {
            this.handleApiError(error);
        } finally {
            this.setState({
                isFiling: false,
                fileUploadIsInProgress: false,
            });
        }
    }

    isFormValid(): boolean {
        const {
            selectedSubmittal,
            selectedResponse,
            from,
            to,
            cc,
            isFiling,
            isLoadingProjects,
            isLoadingKeywords,
            receivedDate,
            subject,
            selectedProject,
        } = this.state;
        const isReviewResponseFormValid =
            !!selectedProject &&
            !!selectedSubmittal &&
            !!selectedResponse &&
            !!from.length &&
            !!to.length &&
            !isFiling &&
            !isLoadingProjects &&
            !isLoadingKeywords &&
            this.props.formValidationHelpers.areAssigneesValid(from, true) &&
            this.props.formValidationHelpers.areAssigneesValid(to, true) &&
            this.props.formValidationHelpers.areAssigneesValid(cc, false) &&
            (this.isCloudProject() ? true : !!receivedDate) && // received date required for npc projects
            (this.isCloudProject() ? true : !!subject) && // subject required for npc projects
            !this.state.areToAndCCSame;

        return isReviewResponseFormValid;
    }

    private handleApiError(error: any): void {
        Log.error(`${this.componentName}.handleAPIerror ${error}`);

        if (ExpiredSessionError.isInstanceOf(error)) {
            this.props.onExpiredSession();
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.WARNING) {
            this.props.onShowToast(this.props.translate(error.messageToDisplay) as string, MessageBarType.warning);
            return;
        }

        if (error.level !== undefined && error.level === ApiRequestErrorLevel.ERROR) {
            const failedAttachmentNames = this.state.failedUploadAttachmentNames;
            if (failedAttachmentNames.length) {
                const messageToDisplay = (this.props.translate(error.messageToDisplay) as string)
                    .replace("[[attachment-names]]", failedAttachmentNames.join("\n"))
                    .replace("[[failed-attachment-count]]", failedAttachmentNames.length.toString());
                this.props.onShowToast(messageToDisplay, MessageBarType.severeWarning);
                return;
            }
            this.props.onShowToast(
                this.props.translate(error.messageToDisplay) as string,
                MessageBarType.severeWarning
            );
            return;
        }
        if (error.status === 501) {
            this.props.onShowToast(
                this.props.translate("SHARED.ERRORS.NL_OUTDATED") as string,
                MessageBarType.severeWarning
            );
            return;
        }

        if (error.level === undefined) {
            this.props.onShowToast(
                this.props.translate("SUBMITTALS.RESPONSE.FAILED_GENERIC") as string,
                MessageBarType.severeWarning
            );
        }
    }

    fileUploadCallback(isInProgress: boolean, failedIds: string[]) {
        const failedAttachmentNames = !isInProgress ? this.getFailedAttachmentNames(failedIds) : [];
        this.setState({
            fileUploadIsInProgress: isInProgress,
            failedUploadAttachmentNames: failedAttachmentNames,
        });
    }

    private getFailedAttachmentNames(failedIds: string[]): string[] {
        return failedIds.length > 0
            ? this.state.attachments.filter((x) => failedIds.includes(x.id)).map((x) => x.name)
            : [];
    }

    private isCloudProject(): boolean {
        const isCloud = this.props.nrnServiceWrapper.isCloudProject(this.state.selectedProject?.key as string);
        this.toggleProjectTeamVisibility(isCloud);
        return isCloud;
    }

    private toggleProjectTeamVisibility(cloudProject: Boolean) {
        const teamMemberDiv = document.querySelector(
            ".ms-CommandBar-secondaryCommand .ms-OverflowSet-item:nth-child(2)"
        );
        cloudProject
            ? teamMemberDiv?.classList.remove("hideProjectTeam")
            : teamMemberDiv?.classList.add("hideProjectTeam");
    }

    private onReceivedDateChange(date: Date | undefined): void {
        this.setState({ receivedDate: date });
    }

    private onKeywordsChanged(items: IPersonaProps[]): void {
        this.setState({ selectedKeywords: items });
    }

    private onSubjectChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        Log.infoStyle(`Subject Change ${newValue}`, `red`);
        this.setState({ subject: newValue || "" });
    }

    private async refreshProjects(): Promise<void> {
        await this.props.invalidateCacheService.invalidateCache(ProjectsCacheKeys.submittalsCacheName);
        return this.loadProjects();
    }

    private onCCAndToComparison(areToAndCCSame: boolean): void {
        this.setState({ areToAndCCSame });
    }

    updateRespondAndCloseDate(date: Date | undefined): void {
        this.setState({ respondAndCloseDate: date });
    }

    updateRespondAndCloseEmailRecipients(emails: EmailRecipients): void {
        this.setState({ respondAndCloseEmailRecipients: emails });
    }

    updateRespondAndCloseResponse(respondAndCloseResponse: string | undefined): void {
        this.setState({ respondAndCloseResponse });
    }

    private handleRespondAndCloseFormValidation = (isValid: boolean): void => {
        this.setState({ isRespondCloseFormValid: isValid });
    };

    updateKeywordsLoadingState(isLoadingPurposes: boolean): void {
        this.setState({ isLoadingPurposes });
    }

    onRespondAndCloseBackButtonClicked() {
        this.setState({ shouldClearRespondAndCloseValues: false });
    }

    translate(value: string): string {
        return this.props.translate(value) as string;
    }
    render(): JSX.Element {
        this.NO_PROJECT_SELECTED = this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_PROJECT_SELECTED");
        this.LOADING = this.props.translate("SHARED.KEYWORD_DROPDOWN.LOADING");
        this.NO_ITEMS = this.props.translate("SHARED.KEYWORD_DROPDOWN.NO_ITEMS");

        this.SUBMITTAL_PICKER_HEADER = this.props.translate("SUBMITTALS.RESPONSE.SUBMITTAL_PICKER_HEADER");

        this.SUBMITTAL_NONE = this.props.translate("SUBMITTALS.RESPONSE.SUBMITTAL_NONE");
        this.SUBMITTAL_PLACEHOLDER = this.props.translate("SUBMITTALS.RESPONSE.SUBMITTAL_PLACEHOLDER");

        this.RESPONSE_LABEL = this.translate("SUBMITTALS.RESPONSE.RESPONSE_LABEL");
        this.RESPONSE_PLACEHOLDER = this.props.translate("SUBMITTALS.RESPONSE.RESPONSE_PLACEHOLDER");
        this.SUBMITTAL_LABEL = this.props.translate("SUBMITTALS.RESPONSE.SUBMITTAL_LABEL");

        const { selectedProject, isLoadingProjects, isLoadingSubmittals, isFiling, isLoadingKeywords } = this.state;

        const responseOptions = this.state.responses;
        const responseDisabled = !selectedProject || isLoadingKeywords || isFiling || !responseOptions?.length;
        const submittalsDisabled =
            !selectedProject || isLoadingSubmittals || isFiling || !this.state.submittals?.length;

        return (
            <div className="submittalReviewResponseComponent">
                <div className="newforma-form newforma-submittalReviewResponseForm">
                    {this.state.shouldShowRespondCloseOptions ? (
                        <RespondCloseSubmittal
                            shouldShowSubmittalResponse={this.shouldGoBackToSubmittalResponse.bind(this)}
                            theme={this.props.theme}
                            logger={this.props.logger}
                            updateRespondAndCloseDate={this.updateRespondAndCloseDate.bind(this)}
                            respondAndCloseDate={this.state.respondAndCloseDate}
                            respondAndCloseSelectedKeyword={this.state.respondAndCloseSelectedKeywords}
                            respondAndCloseKeywords={this.state.respondAndCloseKeywords}
                            respondAndCloseResponse={this.state.respondAndCloseResponse}
                            updateRespondAndCloseResponse={this.updateRespondAndCloseResponse.bind(this)}
                            updateEmails={this.updateRespondAndCloseEmailRecipients.bind(this)}
                            priorEmails={this.state.respondAndCloseEmailRecipients}
                            officeWrapper={this.props.officeWrapper}
                            formValidationHelpers={this.props.formValidationHelpers}
                            projectsService={this.props.projectsService}
                            mailboxItem={this.props.mailboxItem}
                            selectedProject={this.state.selectedProject}
                            onExpiredSession={this.props.onExpiredSession}
                            onFormValidationChange={this.handleRespondAndCloseFormValidation}
                            isFiling={isFiling}
                            updateLoadingState={this.updateKeywordsLoadingState.bind(this)}
                            onShowToast={this.props.onShowToast}
                            updateKeywords={this.updateRespondAndCloseKeywords.bind(this)}
                            updateClearValue={this.onRespondAndCloseBackButtonClicked.bind(this)}
                            configService={this.props.configService}
                            submittalsApiService={this.props.submittalsApiService}
                        />
                    ) : (
                        <>
                            <SuggestedProjectPickerComponent
                                logger={this.props.logger}
                                className="newforma-formSpacing"
                                projects={this.state.projects}
                                myProjects={this.state.projects}
                                onProjectSelected={this.onProjectSelected.bind(this)}
                                disabled={isLoadingProjects || isFiling}
                                smartFilingManager={this.props.smartFilingManager}
                                isLoadingProjects={isLoadingProjects}
                                mailboxItem={this.props.mailboxItem}
                                onRefresh={this.refreshProjects.bind(this)}
                                theme={this.props.theme}
                                selectedProject={selectedProject}
                            />
                            {!this.isCloudProject() ? (
                                <div>
                                    <Label required={true}>
                                        {this.props.translate("SUBMITTALS.SUBJECT_LABEL") as string}
                                    </Label>
                                    <TextField
                                        className="newforma-formSpacing"
                                        id="submittalReviewResponse-subject"
                                        value={this.state.subject}
                                        onChange={this.onSubjectChange.bind(this)}
                                        disabled={this.state.isFiling}
                                        maxLength={256}
                                    />
                                </div>
                            ) : null}

                            <LabelComponent text={this.SUBMITTAL_LABEL} required={true} />

                            <div onMouseDown={this.handleMouseDownSubmittals.bind(this)}>
                                <TagPicker // SUBMITTAL component
                                    className={`newforma-submittalNumber newforma-formSpacing containerClass ${
                                        submittalsDisabled ? `disabledStyle` : ``
                                    }`}
                                    selectedItems={this.state.selectedSubmittal ? [this.state.selectedSubmittal] : []}
                                    pickerSuggestionsProps={{
                                        suggestionsHeaderText: this.SUBMITTAL_PICKER_HEADER,
                                        noResultsFoundText: this.SUBMITTAL_NONE,
                                    }}
                                    onRenderSuggestionsItem={this.onRenderSubmittalNumbers.bind(this)}
                                    disabled={submittalsDisabled}
                                    itemLimit={1}
                                    ref={this.submittalRef}
                                    onBlur={this.onSubmittalBlur.bind(this)}
                                    onResolveSuggestions={this.onSubmittalsFiltered.bind(this)}
                                    onItemSelected={this.onSubmittalSelected.bind(this)}
                                    onChange={this.onSubmittalChange.bind(this)}
                                    pickerCalloutProps={{ calloutWidth: 280 }}
                                    resolveDelay={300}
                                    inputProps={{
                                        placeholder: this.getKeywordPlaceholder(
                                            this.state.submittals?.length,
                                            this.SUBMITTAL_PLACEHOLDER
                                        ),
                                    }}
                                    onEmptyInputFocus={this.onEmptySubmittalInputFocus.bind(this)}
                                />
                            </div>
                            <KeywordsDropdown // RESPONSE component
                                className="newforma-responseDropdown newforma-formSpacing"
                                id="submittalReviewResponse-responseDropdown"
                                options={responseOptions ?? []}
                                label={this.RESPONSE_LABEL}
                                placeholder={this.RESPONSE_PLACEHOLDER}
                                disabled={responseDisabled}
                                isLoading={!!isLoadingKeywords}
                                isProjectSelected={!!selectedProject}
                                required={true}
                                onSelectionChange={this.onResponseSelectionChange.bind(this)}
                                selectFirstOption={false}
                                theme={this.props.theme}
                                selectedPurposeKey={this.state.selectedResponse?.displayOrder}
                                // doesn't exist? onBlur={this.onResponseBlur.bind(this)}
                            />
                            <FromAndToComponent
                                className={"newforma-formSpacing"}
                                isFromRequired={true}
                                isToRequired={true}
                                isCcRequired={false}
                                includeCc={true}
                                officeWrapper={this.props.officeWrapper}
                                disabled={this.state.isFiling}
                                formValidationHelpers={this.props.formValidationHelpers}
                                mailboxItem={this.props.mailboxItem}
                                onFromChange={this.onFromChange.bind(this)}
                                onToChange={this.onToChange.bind(this)}
                                onCcChange={this.onCcChange.bind(this)}
                                logger={this.props.logger}
                                projectsService={this.props.projectsService}
                                onError={this.props.onShowToast}
                                project={selectedProject}
                                shouldHideFrom={false}
                                onEmailCheck={this.onCCAndToComparison.bind(this)}
                                EmailRecipients={this.state.emailRecipients}
                                isForwardInProgress={this.state.shouldRespondAndClose}
                            />
                            <Label required={false}>
                                {this.props.translate("SUBMITTALS.RESPONSE.REMARKS_LABEL") as string}
                            </Label>
                            {this.props.isFileTransferAndEditorSupported ? (
                                <HTMLEditor
                                    value={this.state.remarks}
                                    onRemarksUpdate={this.onRemarksChange.bind(this)}
                                    configService={this.props.configService}
                                    isFiling={isFiling}
                                />
                            ) : (
                                <TextField
                                    className="newforma-formSpacing"
                                    id="submittalReviewResponse-remarks"
                                    multiline
                                    resizable={true}
                                    value={this.state.remarks}
                                    onChange={this.onOldEditorRemarksChange.bind(this)}
                                    disabled={isFiling}
                                    rows={5}
                                    maxLength={65000}
                                />
                            )}
                            {!this.isCloudProject() ? (
                                <>
                                    <TranslatedDatePickerComponent
                                        date={this.state.receivedDate}
                                        label={this.props.translate("SUBMITTALS.RECEIVED_DATE") as string}
                                        onDateChange={this.onReceivedDateChange.bind(this)}
                                        className="newforma-subReviewResponseReceivedDate newforma-formSpacing"
                                        disabled={isFiling}
                                        required={true}
                                        clearDateButtonVisibility={ElementVisibility.None}
                                    />
                                    <ChipPickerComponent
                                        className="newforma-formSpacing newforma-submittalRevResponseKeywords"
                                        required={false}
                                        disabled={isFiling || isLoadingKeywords || !selectedProject}
                                        items={this.state.keywords ? this.state.keywords : []}
                                        selectedItems={this.state.selectedKeywords}
                                        onSelectedItemsChanged={this.onKeywordsChanged.bind(this)}
                                        label={this.props.translate("SUBMITTALS.KEYWORDS_LABEL") as string}
                                        allowCustomInput={this.state.allowCustomKeywords}
                                    />
                                </>
                            ) : null}
                            <AttachmentsComponent
                                attachments={this.state.attachments}
                                disabled={isFiling}
                                allowFileUploads={true}
                                onAttachmentsUpdated={this.onAttachmentsUpdated.bind(this)}
                                isCloudProject={this.isCloudProject()}
                                logger={this.props.logger}
                                isFilePathInAttachmentsSupported={this.props.isFilePathInAttachmentsSupported}
                                classNameForScroll={".submittalReviewResponseComponent"}
                            />

                            {this.props.isRespondCloseSupported ? (
                                <ForwardCheckbox
                                    label={this.props.translate("SUBMITTALS.RESPOND_CLOSE.RESPOND_AND_CLOSE") as string}
                                    subtext={
                                        this.props.translate(
                                            "SUBMITTALS.RESPOND_CLOSE.RESPOND_AND_CLOSE_SUBTEXT"
                                        ) as string
                                    }
                                    shouldForward={this.updateForwardSubmittalCheckbox.bind(this)}
                                    checked={this.state.shouldRespondAndClose}
                                    isFiling={isFiling || this.isRespondCloseDisabled()}
                                    showDisableMessage={this.isRespondCloseDisabled()}
                                    theme={this.props.theme}
                                />
                            ) : null}
                        </>
                    )}
                </div>
                {this.state.fileUploadIsInProgress ? (
                    <ProgressIndicator
                        label={this.props.translate("SHARED.FILE_UPLOAD_PROGRESS_LABEL") as string}
                        className="newforma-progressIndicator"
                        styles={{
                            itemName: ".ms-label",
                        }}
                    />
                ) : null}
                <div id="footer" key="footer" className="newforma-footer">
                    <DefaultButton
                        className="newforma-footerButton"
                        id="fileAsSubmittalResponse"
                        primary={true}
                        onClick={
                            this.state.shouldRespondAndClose
                                ? this.state.shouldShowRespondCloseOptions
                                    ? this.onFormSubmit.bind(this)
                                    : () => this.moveToRespondClose()
                                : this.onFormSubmit.bind(this)
                        }
                        text={
                            this.state.shouldShowRespondCloseOptions
                                ? (this.props.translate(
                                      "SUBMITTALS.RESPOND_CLOSE.SUBMIT_BUTTON"
                                  ) as string).toLocaleUpperCase()
                                : this.state.shouldRespondAndClose
                                ? (this.props.translate(
                                      "SUBMITTALS.RESPOND_CLOSE.CONTINUE_BUTTON"
                                  ) as string).toLocaleUpperCase()
                                : (this.props.translate(
                                      "SUBMITTALS.RESPONSE.SUBMIT_BUTTON"
                                  ) as string).toLocaleUpperCase()
                        }
                        disabled={!this.isFormValid()}
                    />
                </div>
            </div>
        );
    }
}

export default withLocalize(SubmittalReviewResponseComponent);
