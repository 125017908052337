import { SuggestedProject, Suggestion } from "./SuggestedProject";
import FiledProject from "../../models/FiledProject";
import { OfficeRoamingSettings } from "../OfficeRoamingSettings";
import { ProjectCollection } from "./ProjectCollection";
import { OfficeWrapper } from "../OfficeWrapper";
import { Logger } from "../Logger";
import { ITag } from "office-ui-fabric-react";
import { ITagWithNixEnabled } from "../../models/shared/ITagWithNixEnabed";

const sortBy = require("array-sort-by");

export class SmartFilingManager {
    private MIN_VALID_CHAR_LENGTH = 2;
    private fullProjectListReference: { [key: string]: ITag } = {};

    private _filedProjectCollection: ProjectCollection<FiledProject>;

    constructor(
        private officeRoamingSettings: OfficeRoamingSettings,
        private officeWrapper: OfficeWrapper,
        private logger: Logger
    ) {
        this._filedProjectCollection = new ProjectCollection<FiledProject>();
    }

    get filedProjects(): FiledProject[] {
        return this._filedProjectCollection.toArray();
    }

    async addToFiledHistory(project: ITag, conversationId: string, sender: string): Promise<void> {
        // Find filed project in history collection. Add if it doesn't exist
        // NOTE: If email thread has been filed to multiple projects, suggest in order by date/time filed - higher weight for most recent project thread was filed to

        let filedProject: FiledProject | undefined;
        if (this._filedProjectCollection.has(project.key)) {
            filedProject = this._filedProjectCollection.get(project.key);
            const uniquifiedThreads = new Set(filedProject.t);
            uniquifiedThreads.add(conversationId);
            filedProject.t = Array.from(uniquifiedThreads);
            filedProject.d = new Date(); // update the last filed date
        } else {
            filedProject = new FiledProject(project.key, conversationId, sender);
        }
        filedProject.c++; // increment reference count before saving
        this._filedProjectCollection.add(filedProject);

        await this.officeRoamingSettings.saveWithRetry(
            {
                filedProjects: this._filedProjectCollection.toArray(),
            },
            true
        );
    }

    private async getEmailBody() {
        return this.officeWrapper.getCurrentEmailBody();
    }

    private async loadFiledProjectHistory(): Promise<void> {
        let filedProjects = this.officeRoamingSettings.getFiledProjects() || [];
        filedProjects = filedProjects.filter((storedProject) => !!this.fullProjectListReference[storedProject.nrn]);

        this._filedProjectCollection = new ProjectCollection<FiledProject>(filedProjects);
    }

    async getSuggestedProjects(projects: ITagWithNixEnabled[]): Promise<SuggestedProject[]> {
        // the current Office.mailbox.item is null if a subfolder is selected
        if (!this.officeWrapper.currentContextItem) {
            return [];
        }
        for (const project of projects) {
            this.fullProjectListReference[project.key] = project;
        }

        await this.loadFiledProjectHistory();
        const body = await this.getEmailBody();
        const subject = await this.officeWrapper.getCurrentEmailSubject();
        const suggestedProjects = this.setProjectItemsWeight(
            projects,
            body.toLocaleLowerCase(),
            subject.toLocaleLowerCase()
        );

        suggestedProjects.forEach((suggestedProject) => {
            const originalProject = projects.find((proj) => proj.key === suggestedProject.nrn);
            if (originalProject) {
                suggestedProject.nixEnabled = originalProject.nixEnabled;
            }
        });

        this.logSuggestedProjectRankings(suggestedProjects);

        if (suggestedProjects.length) {
            sortBy(suggestedProjects, (item: SuggestedProject) => [
                -item.suggestionWeight,
                -(item.dateFiled || Number.MAX_SAFE_INTEGER),
            ]);
        }

        this.logger.info(`${suggestedProjects.length} project suggestions`);
        return suggestedProjects;
    }

    private setProjectItemsWeight(projects: ITag[], body: string, subject: string): SuggestedProject[] {
        const suggestedProjects = new ProjectCollection<SuggestedProject>();
        for (const project of projects) {
            const suggestion = new Suggestion();
            const lowerCaseProjectName = project.name.toLocaleLowerCase();
            let projectMatch = false;
            const projectNameParts = lowerCaseProjectName.split(" - ");
            for (const namePart of projectNameParts) {
                if (namePart && namePart.length >= this.MIN_VALID_CHAR_LENGTH) {
                    const trimmedNamedPart = namePart.trim();
                    if (body && (body.includes(trimmedNamedPart) || trimmedNamedPart.includes(body))) {
                        projectMatch = !!suggestion.bodyKeywordMatch();
                    }
                    if (subject && (subject.includes(trimmedNamedPart) || trimmedNamedPart.includes(subject))) {
                        projectMatch = !!suggestion.subjectKeywordMatch();
                    }
                }
            }

            if (projectMatch) {
                let suggestedProject = suggestedProjects.get(project.key);
                if (!suggestedProject) {
                    suggestedProject = new SuggestedProject(project, suggestion);
                    suggestedProjects.add(suggestedProject);
                }
            }
            this.adjustFiledProjectSuggestionWeight(project, suggestedProjects);
        }
        return suggestedProjects.toArray();
    }

    private adjustFiledProjectSuggestionWeight(
        project: ITag,
        suggestedProjects: ProjectCollection<SuggestedProject>
    ): void {
        const filedProject = this._filedProjectCollection.get(project.key);
        if (!filedProject) {
            return;
        }

        let suggestion = new Suggestion();
        let suggestedProject = suggestedProjects.get(filedProject.nrn);

        if (suggestedProject) {
            suggestion = suggestedProject.suggestion;
            suggestedProject.filingFrequency = filedProject.c;
            suggestedProject.dateFiled = filedProject.d;
        } else {
            suggestedProject = new SuggestedProject(filedProject, suggestion);

            if (suggestedProjects.has(project.key)) {
                suggestion = suggestedProjects.get(project.key).suggestion;
            }
            suggestedProject = new SuggestedProject(filedProject, suggestion);
            suggestedProject.name = project.name;
        }

        if (filedProject.t?.includes(this.officeWrapper.currentConversationId)) {
            suggestion.isThread();
        }

        const senderAddress = this.officeWrapper.getSenderEmailAddress();
        const userAddress = this.officeWrapper.userProfileEmailAddress;
        if (senderAddress && senderAddress !== userAddress) {
            const userDomain = this.officeWrapper.userProfileEmailDomain;
            const senderDomain = this.officeWrapper.getContextMailboxItemSenderEmailDomain();

            const senderMatch = filedProject?.s === senderAddress;
            if (senderMatch) {
                if (userDomain === senderDomain) {
                    suggestion.isInternalSender();
                } else {
                    suggestion.isExternalSender();
                }
            }
        }

        suggestion.isRecent(); // if it's in the FiledProjects it's recent
        suggestedProject.suggestion = suggestion;
        suggestedProjects.add(suggestedProject);
    }

    private logSuggestedProjectRankings(projectsArray: SuggestedProject[]): void {
        const logFormattedSuggestionsList = projectsArray.map((project) => {
            const projectData: { [key: string]: { [key: string]: any } } = {};
            projectData[project.name] = {
                "Suggestion Types Hit": project.rankedTypes,
                "Total Suggestion Weight": project.suggestionWeight,
                "Last Filed Date": project.dateFiled?.toISOString(),
            };
            return projectData;
        });
        this.logger.info(`Suggestions List: ${JSON.stringify(logFormattedSuggestionsList)}`);
    }
}
