import * as ReactDOM from "react-dom";
import { OfficeRoamingSettings } from "./OfficeRoamingSettings";
import { LocalizeProvider } from "react-localize-redux";
import * as React from "react";
import * as OfficeHelpers from "@microsoft/office-js-helpers";
import { AnalyticsManager } from "./AnalyticsManager";
import { NewformaApiClient } from "./NewformaApi/NewformaApiClient";
import { Authenticator } from "./Authenticator";
import { SmartFilingManager } from "./SmartFiling/SmartFilingManager";
import AppComponent from "../components/app/AppComponent";
import { OfficeWrapper } from "./OfficeWrapper";
import { OfficeNotificationService } from "./officeUi/OfficeNotificationService";
import { ConfigurationService } from "./ConfigurationService";
import { UnauthenticatedNewformaApiClient } from "./UnauthenticatedNewformaApiClient";
import { initializeFileTypeIcons } from "@uifabric/file-type-icons";
import { ActionItemApiService } from "./NewformaApi/ActionItemApiService";
import { StorageWrapper } from "./StorageWrapper";
import { ConfigurationsApiService } from "./NewformaApi/ConfigurationsApiService";
import LoginRedirectComponent from "../components/login/LoginRedirectComponent";
import { WindowWrapper } from "./WindowWrapper";
import { FormValidationHelpers } from "../helpers/FormValidationHelpers";
import { TranslationService } from "./TranslationService";
import { CustomerSupportEmailService } from "./CustomerSupportEmailService";
import { Logger } from "./Logger";
import { IProjectsService } from "./NewformaApi/IProjectsService";
import { SendAndFileHelpers } from "../helpers/SendAndFile/SendAndFileHelpers";
import { DateHelpers } from "../helpers/DateHelpers";
import { SubmittalsApiService } from "./NewformaApi/SubmittalsApiService";
import { EulaApiService } from "./NewformaApi/EulaApiService";
import { RfiApiService } from "./NewformaApi/RfiApiService";
import { EmailApiService } from "./NewformaApi/EmailApiService";
import { ProjectsApiService } from "./NewformaApi/ProjectsApiService";
import { MsGraphApiService } from "./MsGraphApiService";
import { NrnServiceWrapper } from "./NrnServiceWrapper";
import { AuthApiService } from "./NewformaApi/AuthApiService";
import { InvalidateCacheService } from "./NewformaApi/InvalidateCacheService";
import { Messenger } from "./Messenger";
import { FileTransferApiService } from "./NewformaApi/FileTransferApiService";
import { OutlookApiService } from "./OutlookApiService";
import GainSight from "./GainSight";

enum GoogleAnalyticsTrackingId {
    CustomerG4 = "G-36H1QRPS1P",
    DevG4 = "G-L7JWFYQJM0",
    Customer = "UA-12933740-65",
    Dev = "UA-12933740-62",
}

export class EntryPoint {
    private isLoginRedirect = false;
    private gainSightAnalytics: any = undefined;

    constructor(
        private authenticator: Authenticator,
        private apiClient: NewformaApiClient,
        private unauthenticatedApiClient: UnauthenticatedNewformaApiClient,
        private analyticsManager: AnalyticsManager,
        private smartFilingManager: SmartFilingManager,
        private officeWrapper: OfficeWrapper,
        private officeRoamingSettings: OfficeRoamingSettings,
        private officeNotificationService: OfficeNotificationService,
        private configService: ConfigurationService,
        private actionItemApiService: ActionItemApiService,
        private storageWrapper: StorageWrapper,
        private configurationsApiService: ConfigurationsApiService,
        private windowWrapper: WindowWrapper,
        private formValidationHelpers: FormValidationHelpers,
        private translationService: TranslationService,
        private customerSupportEmailService: CustomerSupportEmailService,
        private logger: Logger,
        private projectsService: IProjectsService,
        private sendAndFileHelpers: SendAndFileHelpers,
        private dateHelpers: DateHelpers,
        private submittalsApiService: SubmittalsApiService,
        private eulaApiService: EulaApiService,
        private rfiApiService: RfiApiService,
        private emailApiService: EmailApiService,
        private projectsApiService: ProjectsApiService,
        private nrnServiceWrapper: NrnServiceWrapper,
        private msGraphApiService: MsGraphApiService,
        private authApiService: AuthApiService,
        private readonly invalidateCacheService: InvalidateCacheService,
        private readonly messenger: Messenger,
        private readonly fileTransferService: FileTransferApiService,
        private readonly outlookApiService: OutlookApiService
    ) {
        initializeFileTypeIcons("https://spoprod-a.akamaihd.net/files/fabric/assets/item-types-fluent/");
        this.initializeAddin();
    }

    private initializeAddin() {
        const locationHash = this.windowWrapper.locationHash();
        this.isLoginRedirect = locationHash.startsWith("#loginrouting");

        if (this.isLoginRedirect) {
            this.render();
            return;
        }

        if (OfficeHelpers.Authenticator.isAuthDialog()) {
            // This fires when App.refreshToken() uses displayInIframe: true with Office.context.ui.displayDialogAsync
            return;
        }

        // Init Google Analytics integration
        this.analyticsManager.initialize(this.getAnalyticsTrackingId());
        // TODO: remove below line once old google analytics is removed
        this.analyticsManager.initialize(this.getOldAnalyticsTrackingId());
        const location = locationHash.replace(/[#/]/g, "");
        this.analyticsManager.pageview(`/${location}`);

        // Init GainSight
        this.gainSightAnalytics = new GainSight(this.officeWrapper);
        this.gainSightAnalytics.initialize();

        this.render();
    }

    private getAnalyticsTrackingId(): GoogleAnalyticsTrackingId {
        const domain = this.officeWrapper.userProfileEmailDomain;
        const isInternal = domain.includes("newforma") || domain.includes("newforming");
        return isInternal ? GoogleAnalyticsTrackingId.DevG4 : GoogleAnalyticsTrackingId.CustomerG4;
    }

    private getOldAnalyticsTrackingId(): GoogleAnalyticsTrackingId {
        const domain = this.officeWrapper.userProfileEmailDomain;
        const isInternal = domain.includes("newforma") || domain.includes("newforming");
        return isInternal ? GoogleAnalyticsTrackingId.Dev : GoogleAnalyticsTrackingId.Customer;
    }

    render(): void {
        ReactDOM.render(
            <LocalizeProvider>
                {this.isLoginRedirect ? (
                    <LoginRedirectComponent
                        translationService={this.translationService}
                        windowWrapper={this.windowWrapper}
                    />
                ) : (
                    <AppComponent
                        officeRoamingSettings={this.officeRoamingSettings}
                        authenticator={this.authenticator}
                        apiClient={this.apiClient}
                        unauthenticatedApiClient={this.unauthenticatedApiClient}
                        analyticsManager={this.analyticsManager}
                        smartFilingManager={this.smartFilingManager}
                        officeWrapper={this.officeWrapper}
                        officeNotificationService={this.officeNotificationService}
                        configService={this.configService}
                        actionItemApiService={this.actionItemApiService}
                        storageWrapper={this.storageWrapper}
                        configurationsApiService={this.configurationsApiService}
                        windowWrapper={this.windowWrapper}
                        formValidationHelpers={this.formValidationHelpers}
                        translationService={this.translationService}
                        customerSupportEmailService={this.customerSupportEmailService}
                        logger={this.logger}
                        projectsService={this.projectsService}
                        sendAndFileHelpers={this.sendAndFileHelpers}
                        dateHelpers={this.dateHelpers}
                        submittalsApiService={this.submittalsApiService}
                        eulaApiService={this.eulaApiService}
                        rfiApiService={this.rfiApiService}
                        emailApiService={this.emailApiService}
                        projectApiService={this.projectsApiService}
                        msGraphApiService={this.msGraphApiService}
                        nrnServiceWrapper={this.nrnServiceWrapper}
                        authApiService={this.authApiService}
                        invalidateCacheService={this.invalidateCacheService}
                        messenger={this.messenger}
                        fileTransferApiService={this.fileTransferService}
                        outlookApiService={this.outlookApiService}
                    />
                )}
            </LocalizeProvider>,
            document.getElementById("container")
        );
    }
}
